import React, { useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import './add-audience.css'
import AdminButton from '../../utils/adminButton/AdminButton'
import InfoModal from '../../utils/infoModal/InfoModal'
import audienceService from '../../../services/audienceService'
import { type AudienceType } from '../../../utils/types'

interface AddAudienceProps {
  closeModal: () => void
  onAddAudience: (audience: AudienceType) => void
}

const AddAudience: React.FC<AddAudienceProps> = ({ closeModal, onAddAudience }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<AudienceType>({
    defaultValues: {
      name: '',
      frequency: null,
      minOrder: null,
      maxOrder: null
    }
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalText, setmodalText] = useState('')
  const [shouldCloseModal, setShouldCloseModal] = useState(false)

  const onSubmit: SubmitHandler<AudienceType> = (data: AudienceType) => {
    const minOrder = data.minOrder || 1
    const maxOrder = data.maxOrder || 999

    const numericMinOrder = Number(minOrder)
    const numericMaxOrder = Number(maxOrder)
    const numericFrequence = Number(data.frequency)

    if (numericMinOrder > numericMaxOrder) {
      setmodalText('Les valeurs renseignées pour les min/max sont incohérentes')
      setIsModalOpen(true)
      setShouldCloseModal(false)
    } else {
      const payload = {
        ...data,
        frequency: numericFrequence,
        minOrder: numericMinOrder,
        maxOrder: numericMaxOrder
      }

      audienceService
        .createAudience(payload)
        .then((res) => {
          setmodalText("L'audience a été crée avec succès")
          setIsModalOpen(true)
          onAddAudience(res)
          setShouldCloseModal(true)
        })
        .catch(() => {
          setmodalText(
            "Une erreur est survenue lors de la création de l'Audience"
          )
          setIsModalOpen(true)
        })
    }
  }

  return (
    <>
      <form className='audience-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='inputs-container'>
          <div className='input-form-container'>
            <p className='input-label'>Nom</p>
            <input
              className='input-text'
              {...register('name', {
                required: 'Ce champ est obligatoire',
                pattern: {
                  value: /^[a-zA-Z0-9_]+$/,
                  message: 'Les caractère saisis sont incorrects'
                }
              })}
            />
            {errors.name && (
              <p className='validation-error'>{errors.name.message}</p>
            )}
          </div>

          <div className='input-form-container'>
            <p className='input-label'>Fréquence</p>
            <input
              className='input-text'
              {...register('frequency', {
                required: 'Ce champ est obligatoire',
                pattern: {
                  value: /^(?!0$)\d+$/,
                  message: 'Les caractère saisis sont incorrects'
                }
              })}
            />
            {errors.frequency && (
              <p className='validation-error'>{errors.frequency.message}</p>
            )}
          </div>

          <div className='input-form-container'>
            <p className='input-label'>Minimum de commande</p>
            <input
              className='input-text'
              {...register('minOrder', {
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Les caractère saisis sont incorrects'
                },
                min: { value: 1, message: 'La valeur minimale est de 1' }
              })}
            />
            {errors.minOrder && (
              <p className='validation-error'>{errors.minOrder.message}</p>
            )}
          </div>

          <div className='input-form-container'>
            <p className='input-label'>Maximum de commande</p>
            <input
              className='input-text'
              {...register('maxOrder', {
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Les caractère saisis sont incorrects'
                },
                max: { value: 999, message: 'La valeur maximum est de 999' }
              })}
            />
            {errors.maxOrder && (
              <p className='validation-error'>{errors.maxOrder.message}</p>
            )}
          </div>
        </div>
        <AdminButton type='submit'>Créer</AdminButton>
      </form>
      <InfoModal
        isOpen={isModalOpen}
        title='Ajouter une nouvelle audience'
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
          if (shouldCloseModal) {
            closeModal()
          }
        }}
      />
    </>
  )
}

export default AddAudience
