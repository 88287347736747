import React, { type ReactElement } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import './const.css'
import AdminPanel from './Pages/adminPanel/AdminPanel'

function App (): ReactElement {
  return (
    <Router>
      <Routes>
        <Route element={<AdminPanel />} path='/admin/*' />

        {/* other routes here */}
      </Routes>
    </Router>
  )
}

export default App
