import React, { useEffect, useState } from 'react'
import './list-product.css'
import { type ProductType } from '../../../utils/types'
import AdminTable from '../../utils/adminTable/AdminTable'
import InfoModal from '../../utils/infoModal/InfoModal'
import Modal from '../../utils/modal/Modal'
import { ReactComponent as EditIcon } from '../../../assets/edit_icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/delete_icon.svg'
import productService from '../../../services/productService'
import EditProduct from '../editProduct/EditProduct'
import imageService from '../../../services/imageService'
import AddImage from '../addImage/AddImage'

interface ListProductProps {
  productsList: ProductType[]
}

const ListProduct: React.FC<ListProductProps> = ({ productsList }) => {
  const [products, setProducts] = useState<ProductType[]>(productsList)
  const [selectedProduct, setSelectedProduct] = useState<Partial<ProductType>>({
    brand: '',
    code: '',
    label: '',
    minToOrder: null,
    maxToOrder: null,
    minToWinPoints: null,
    maxToWinPoints: null,
    pointValue: null
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [modalText, setmodalText] = useState('')
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [currentProduct, setCurrentProduct] = useState<ProductType>()

  const apiBaseUrl = process.env.REACT_APP_API_URL

  const columns = [
    { header: 'MARQUE', accessor: 'brand' },
    { header: 'CODE PRODUIT', accessor: 'code' },
    { header: 'LIBELLE PRODUIT', accessor: 'label' },
    { header: 'MINIMUM PRODUIT', accessor: 'minToOrder' },
    { header: 'MAXIMUM PRODUIT', accessor: 'maxToOrder' },
    { header: 'POINT MINIMUM', accessor: 'minToWinPoints' },
    { header: 'POINT MAXIMUM', accessor: 'maxToWinPoints' },
    { header: 'NOMBRE DE POINTS', accessor: 'pointValue' },
    {
      header: 'IMAGES',
      accessor: 'images',
      render: (row: ProductType) => (
        <>
          <img
            src={
              row.imageUrl
                ? `${apiBaseUrl}/images/${row.imageUrl}`
                : `${apiBaseUrl}/images/image_placeholder.png`
            }
            alt='Product'
            style={{ width: '50px', height: '50px', cursor: 'pointer' }}
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            onClick={async () => {
              await handleOpenImageModal(row)
            }}
          />
        </>
      )
    },
    {
      header: 'ACTIONS',
      accessor: 'actions',
      render: (row: ProductType) => (
        <>
          <EditIcon
            className='admin-icons'
            onClick={() => {
              updateProduct(row)
            }}
            style={{ marginRight: '10px', color: 'green' }}
          >
            Update
          </EditIcon>
          <DeleteIcon
            className='admin-icons'
            onClick={() => {
              if (row.id) {
                void deleteProductAction(row.id)
              }
            }}
          >
            Delete
          </DeleteIcon>
        </>
      )
    }
  ]

  const deleteProductAction = async (id: number): Promise<void> => {
    console.log('Deleting product with id:', id)
    try {
      await productService.deleteProduct(id).then(() => {
        setmodalText('Le Produit a été supprimé avec succès')
        setIsModalOpen(true)
        setProducts(products.filter((a) => a.id !== id))
      })
    } catch (error) {
      console.log(error)
    }
  }

  const updateProduct = (product: ProductType): void => {
    setSelectedProduct(product)
    setIsEditModalOpen(true)
  }

  const handleEditProduct = (updatedProduct: ProductType): void => {
    setProducts(
      products.map((product) =>
        product.id === updatedProduct.id ? updatedProduct : product
      )
    )
  }

  useEffect(() => {
    setProducts(productsList)
  }, [productsList])

  const fetchImages = async (): Promise<void> => {
    const urls = await imageService.listImages()
    setImageUrls(urls)
  }

  const handleOpenImageModal = async (product: ProductType): Promise<void> => {
    try {
      setCurrentProduct(product)
      setIsImageModalOpen(true)
      await fetchImages()
    } catch (error) {
      console.error('Failed to delete image:', error)
    }
  }

  return (
    <div className='products-container'>
      <AdminTable columns={columns} data={products} />
      <InfoModal
        isOpen={isModalOpen}
        title="Supprimer le produit"
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />
      <Modal
        isOpen={isEditModalOpen}
        title='Ajouter un nouveau produit'
        onClose={() => {
          setIsEditModalOpen(false)
        }}
      >
        <EditProduct
          productProp={selectedProduct}
          closeModal={() => {
            setIsEditModalOpen(false)
          }}
          onEditProduct={handleEditProduct}
        />
      </Modal>
      <Modal
        isOpen={isImageModalOpen}
        title='Associé une nouvelle image au produit'
        onClose={() => {
          setIsImageModalOpen(false)
        }}
      >
        <AddImage
          closeModal={() => {
            setIsImageModalOpen(false)
          }}
          imageUrls={imageUrls}
          currentProduct={currentProduct}
          setProducts={setProducts}
        />
      </Modal>
    </div>
  )
}

export default ListProduct
