import React, { useState, type ReactElement, useEffect } from 'react'

import './products.css'
import AdminButton from '../utils/adminButton/AdminButton'
import Modal from '../utils/modal/Modal'
import AddProducts from './addProduct/AddProduct'
import { type ProductType } from '../../utils/types'
import ListProduct from './listProduct/ListProduct'
import productService from '../../services/productService'

const Products = (): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [products, setProducts] = useState<ProductType[]>([])

  const handleAddProduct = async (): Promise<void> => {
    try {
      const response = await productService.getAllProducts()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setProducts(response)
    } catch (error) {
      console.error('Failed to fetch audiences:', error)
    }
  }

  useEffect(() => {
    const fetchProducts = async (): Promise<void> => {
      try {
        const response = await productService.getAllProducts()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setProducts(response)
      } catch (error) {
        console.error('Failed to fetch audiences:', error)
      }
    }
    void fetchProducts()
  }, [])

  return (
    <div className='products-container'>
      <AdminButton
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        CREER PRODUIT
      </AdminButton>

        <ListProduct productsList={products} />
      <Modal
        isOpen={isModalOpen}
        title='Ajouter un nouveau produit'
        onClose={() => {
          setIsModalOpen(false)
        }}
      >
        <AddProducts
          closeModal={() => {
            setIsModalOpen(false)
          }}
          onAddProduct={handleAddProduct}
        />
      </Modal>
    </div>
  )
}

export default Products
