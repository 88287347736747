import React, { useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import './edit-product.css'
import AdminButton from '../../utils/adminButton/AdminButton'
import InfoModal from '../../utils/infoModal/InfoModal'
import { type ProductType } from '../../../utils/types'
import productService from '../../../services/productService'
interface EditProductProps {
  onEditProduct: (product: ProductType) => void
  closeModal: () => void
  productProp: Partial<ProductType>
}

const EditProduct: React.FC<EditProductProps> = ({
  productProp,
  onEditProduct,
  closeModal
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<ProductType>({
    defaultValues: productProp
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalText, setmodalText] = useState('')
  const [shouldCloseModal, setShouldCloseModal] = useState(false)
  const maxToWinPoints = watch('maxToWinPoints')
  const minToWinPoints = watch('minToWinPoints')

  const pointValueRequired = Boolean(maxToWinPoints || minToWinPoints)

  const onSubmit: SubmitHandler<ProductType> = (data: ProductType) => {
    const numericMinToOrder = Number(data.minToOrder) || 0
    const numericMaxToOrder = Number(data.maxToOrder) || 0
    const numericMinToWinPoints = Number(data.minToWinPoints) || 0
    const numericMaxToWinPoints = Number(data.maxToWinPoints) || 0

    if (
      numericMinToOrder > numericMaxToOrder ||
      numericMinToWinPoints > numericMaxToWinPoints
    ) {
      setmodalText('Les Valeurs renseignées pour les min/max sont incohérentes')
      setIsModalOpen(true)
      setShouldCloseModal(false)
    } else {
      productService
        .updateProduct(data.id, data)
        .then((res) => {
          setmodalText('Le produit à été mis à jour avec succès.')
          setIsModalOpen(true)
          onEditProduct(data)
          setShouldCloseModal(true)
        })
        .catch(() => {
          setmodalText(
            "Une erreur est survenue lors de la création de l'Produit"
          )
          setIsModalOpen(true)
        })
    }
  }

  return (
    <>
      <form className='audience-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='inputs-container'>
          <div className='input-form-container'>
            <p className='input-label'>Marque</p>
            <input
              className='input-text'
              {...register('brand', {
                required: 'Ce champ est obligatoire',
                pattern: {
                  value: /^[a-zA-Z0-9_ ]+$/,
                  message: 'Les caractère saisis sont incorrects'
                }
              })}
            />
            {errors.brand && (
              <p className='validation-error'>{errors.brand.message}</p>
            )}
          </div>
          <div className='input-form-container'>
            <p className='input-label'>Libelle Produit</p>
            <input
              className='input-text'
              {...register('label', {
                required: 'Ce champ est obligatoire',
                pattern: {
                  value: /^[a-zA-Z0-9_ ]+$/,
                  message: 'Les caractère saisis sont incorrects'
                }
              })}
            />
            {errors.label && (
              <p className='validation-error'>{errors.label.message}</p>
            )}
          </div>
          <div className='input-form-container full-width' >
            <p className='input-label'>Code Produit</p>
            <input
              className='input-text'
              {...register('code', {
                required: 'Ce champ est obligatoire',
                pattern: {
                  value: /^[a-zA-Z0-9_ ]+$/,
                  message: 'Les caractère saisis sont incorrects'
                }
              })}
            />
            {errors.code && (
              <p className='validation-error'>{errors.code.message}</p>
            )}
          </div>

          <div className='input-form-container'>
            <p className='input-label'>Minimum Produit</p>
            <input
              className='input-text'
              {...register('minToOrder', {
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Les caractère saisis sont incorrects'
                },
                min: { value: 1, message: 'La valeur minimale est de 1' }
              })}
            />
            {errors.minToOrder && (
              <p className='validation-error'>{errors.minToOrder.message}</p>
            )}
          </div>

          <div className='input-form-container'>
            <p className='input-label'>Maximum Produit</p>
            <input
              className='input-text'
              {...register('maxToOrder', {
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Les caractère saisis sont incorrects'
                },
                max: { value: 999, message: 'La valeur maximum est de 999' }
              })}
            />
            {errors.maxToOrder && (
              <p className='validation-error'>{errors.maxToOrder.message}</p>
            )}
          </div>

          <div className='input-form-container'>
            <p className='input-label'>Minimum Point</p>
            <input
              className='input-text'
              {...register('minToWinPoints', {
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Les caractère saisis sont incorrects'
                },
                min: { value: 1, message: 'La valeur minimale est de 1' }
              })}
            />
            {errors.minToWinPoints && (
              <p className='validation-error'>
                {errors.minToWinPoints.message}
              </p>
            )}
          </div>

          <div className='input-form-container'>
            <p className='input-label'>Maximum point</p>
            <input
              className='input-text'
              {...register('maxToWinPoints', {
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Les caractère saisis sont incorrects'
                },
                max: { value: 999, message: 'La valeur maximum est de 999' }
              })}
            />
            {errors.maxToWinPoints && (
              <p className='validation-error'>
                {errors.maxToWinPoints.message}
              </p>
            )}
          </div>

          <div className='input-form-container full-width'>
            <p className='input-label'>Nombre de points</p>
            <input
              className='input-text'
              {...register('pointValue', {
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Les caractère saisis sont incorrects'
                },
                required:
                  pointValueRequired &&
                  'Ce champ est requis lorsque Minimum point ou Maximum point est renseigné',
                max: { value: 999, message: 'La valeur maximum est de 999' },
                min: { value: 1, message: 'La valeur minimale est de 1' }
              })}
            />
            {errors.pointValue && (
              <p className='validation-error'>{errors.pointValue.message}</p>
            )}
          </div>
        </div>
        <AdminButton type='submit'>Mettre à jour</AdminButton>
      </form>
      <InfoModal
        isOpen={isModalOpen}
        title='Mise à jour Produit'
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
          if (shouldCloseModal) {
            closeModal()
          }
        }}
      />
    </>
  )
}

export default EditProduct
