// FileInput.tsx

import React, { useEffect, useState, type DragEvent } from 'react'
import imageService from '../../services/imageService'
import AdminButton from '../utils/adminButton/AdminButton'
import './images-library.css'
import { ReactComponent as DeleteIcon } from '../../assets/remove_icon.svg'
import { ReactComponent as ImportIcon } from '../../assets/import_icon.svg'
import InfoModal from '../utils/infoModal/InfoModal'
import ConfirmationModal from '../utils/confirmationModal/ConfirmationModal'

const ImagesLibrary: React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<FileList>()
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [fileToDelete, setFileToDelete] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [modalText, setmodalText] = useState('')
  const apiBaseUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    void fetchImages()
  }, [])

  const fetchImages = async (): Promise<void> => {
    try {
      const urls = await imageService.listImages()
      setImageUrls(
        urls.filter(
          (url: string) => url !== `${apiBaseUrl}/images/image_placeholder.png`
        )
      )
    } catch (error) {
      console.error('Failed to fetch images:', error)
    }
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.files) {
      setSelectedFiles(event.target.files)
    }
  }

  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()
    const formData = new FormData()
    let fileFormatCheck = false
    if (selectedFiles && selectedFiles.length > 0) {
      Array.from(selectedFiles).forEach((file: File) => {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
          fileFormatCheck = true
        }
        formData.append('images', file)
      })
      if (fileFormatCheck) {
        setmodalText("Le type de l'image importée n'est pas conforme.")
        setIsModalOpen(true)
      } else {
        try {
          await imageService.uploadImages(formData).then(() => {
            setmodalText('Image(s) importée(s) avec succès')
            setIsModalOpen(true)
            setSelectedFiles(undefined)
          })
          await fetchImages()
        } catch (error) {
          console.error('Failed to upload images:', error)
        }
      }
    }
  }

  const handleDeleteImage = async (imageUrl: string): Promise<void> => {
    const filename = imageUrl.split('/').pop()
    if (filename) {
      try {
        await imageService.deleteImage(filename)
        await fetchImages()
      } catch (error) {
        console.error('Failed to delete image:', error)
      }
    }
  }

  const onDrop = (e: DragEvent<HTMLLabelElement>): void => {
    e.preventDefault()
    const { files } = e.dataTransfer
    if (files.length > 0) {
      setSelectedFiles(files)
    }
  }

  const onDragOver = (e: DragEvent<HTMLLabelElement>): void => {
    e.preventDefault()
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '100px'
      }}
    >
      <form
        style={{
          width: '100%',
          position: 'relative'
        }}
        onSubmit={handleSubmit}
      >
        <label
          className={`label ${
            selectedFiles ? 'border-green-500' : 'border-gray-300'
          }`}
          htmlFor='file_upload'
          onDragOver={onDragOver}
          onDrop={onDrop}
        >
          <span className='span-container'>
            <ImportIcon
              className={`icon ${
                selectedFiles ? 'text-green-600' : 'text-gray-600'
              }`}
            />
            <span
              className={`font-medium ${
                selectedFiles ? 'text-green-600' : 'text-gray-600'
              }`}
            >
              {selectedFiles
                ? Array.from(selectedFiles)
                  .map((file) => file.name)
                  .join(', ')
                : 'Importer des Images'}
            </span>
          </span>
          <input
            className='hidden'
            name='file_upload'
            onChange={handleFileChange}
            type='file'
            id='file_upload'
            accept='image/jpeg, image/png'
            multiple
          />
        </label>
        <AdminButton type='submit' className='import-button'>
          IMPORTER
        </AdminButton>
      </form>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(6, 1fr)',
          gap: '30px',
          marginTop: '50px'
        }}
      >
        {imageUrls.map((url) => (
          <div
            key={url}
            style={{
              position: 'relative',
              display: 'inline-block',
              width: '100%',
              height: '200px'
            }}
          >
            <img
              src={url}
              alt='Uploaded'
              style={{
                width: '100%',
                height: '100%',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
              className='list-image-item'
            />
            <DeleteIcon
              style={{
                position: 'absolute',
                top: 4,
                right: 2,
                cursor: 'pointer'
              }}
              onClick={async () => {
                setFileToDelete(url)
                setIsConfirmationModalOpen(true)
              }}
            >
              Delete
            </DeleteIcon>
          </div>
        ))}
      </div>

      <InfoModal
        isOpen={isModalOpen}
        title='Import Images'
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title='Supprimer une image'
        message='Voulez-Vous supprimer cette image ?'
        onClose={() => {
          setIsConfirmationModalOpen(false)
        }}
        onConfirme={async () => {
          console.log('file to delete: ', fileToDelete)
          await handleDeleteImage(
            fileToDelete.replace(`${apiBaseUrl}/images/`, '')
          )
          setIsConfirmationModalOpen(false)
        }}
      ></ConfirmationModal>
    </div>
  )
}

export default ImagesLibrary
