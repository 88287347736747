import axios from 'axios'
import { type ProductType } from '../utils/types'

const apiBaseUrl = process.env.REACT_APP_API_URL

const createProduct = async (
  newProduct: Partial<ProductType>
): Promise<ProductType> => {
  try {
    const response = await axios.post(`${apiBaseUrl}/products`, newProduct)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const getAllProducts = async (): Promise<ProductType[]> => {
  try {
    const response = await axios.get<ProductType[]>(`${apiBaseUrl}/products`)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const deleteProduct = async (id: number): Promise<void> => {
  try {
    const response = await axios.delete(`${apiBaseUrl}/products/${id}`)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const updateProduct = async (id: number, newObject: ProductType): Promise<void> => {
  try {
    const response = await axios.put(`${apiBaseUrl}/products/${id}`, newObject)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

export default {
  createProduct,
  getAllProducts,
  deleteProduct,
  updateProduct
}
