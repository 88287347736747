import axios from 'axios'

const apiBaseUrl = process.env.REACT_APP_API_URL

const uploadImages = async (formData: FormData): Promise<string> => {
  try {
    const response = await axios.post(
          `${apiBaseUrl}/products/upload-images`,
          formData
    )
    return (response.data)
  } catch (err) {
    if (axios.isAxiosError(err)) {
      console.error(err.response?.data?.message)
      return err.response?.data?.message
    } else {
      console.error('An unexpected error occurred')
      return 'An unexpected error occurred'
    }
  }
}

const listImages = async (): Promise<[string]> => {
  try {
    const response = await axios.get(`${apiBaseUrl}/products/images-list`)
    return response.data
  } catch (err) {
    if (axios.isAxiosError(err)) {
      console.error(err.response?.data?.message)
      return ['']
    } else {
      console.error('An unexpected error occurred')
      return ['']
    }
  }
}

const deleteImage = async (filename: string): Promise<void> => {
  await axios.delete(`${apiBaseUrl}/products/delete-image/${filename}`)
}

const updateProductImage = async (productId: number, imageUrl: string): Promise<void> => {
  await axios.patch(`${apiBaseUrl}/products/update-image/${productId}`, { imageUrl })
}

export default { uploadImages, listImages, deleteImage, updateProductImage }
