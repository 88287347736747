import React from 'react'
import './info-modal.css'
import AdminButton from '../adminButton/AdminButton'

interface InfoModalProps {
  isOpen: boolean
  onClose: () => void
  title?: string
  message: string
}

const InfoModal: React.FC<InfoModalProps> = ({ isOpen, onClose, title, message }) => {
  if (!isOpen) return null

  return (
    <div className='modal-overlay' onClick={onClose}>
      <div
        className='info-modal-content'
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
                <button className='modal-close' onClick={onClose}>
          &times;
        </button>
        <div className='info-modal-title'>
          <div>{title}</div>
        </div>
        <div className='info-modal-body'>

        {message}
        </div>
        <AdminButton className='' onClick={onClose}>
            Fermer
        </AdminButton>
      </div>
      </div>
  )
}

export default InfoModal
